"use client";

import { Component, createContext, useContext } from "react";

// Declaring the state object globally.
const initial_state = {
  logged_in: false,
  user: null,
  role: null,
} as {
  logged_in: boolean;
  user: any | null;
  role: string | null;
  is_loading: boolean;
};

// Modify the userContextWrapper function
const userContextWrapper = (
  component?: React.Component,
  initialUser?: any
) => ({
  ...initial_state,
  user: initialUser || null,
  role: initialUser?.role || null,
  logged_in: !!initialUser,
  login: () => {
    initial_state.logged_in = true;
    component?.setState({ context: userContextWrapper(component) });
  },
  set__user: (user: any | null) => {
    initial_state.user = user;
    initial_state.role = user?.role;
    component?.setState({ context: userContextWrapper(component) });
  },
  logout: () => {
    initial_state.logged_in = false;
    initial_state.user = null;
    initial_state.role = null;
    component?.setState({ context: userContextWrapper(component) });
  },
});

type Context = ReturnType<typeof userContextWrapper>;

export const UserContext = createContext<Context>(userContextWrapper());

// Update the Props interface
interface Props {
  children: React.ReactNode;
  initialUser?: any;
}

// Modify the UserContextProvider class
export class UserContextProvider extends Component<Props> {
  state: { context: Context } = {
    context: userContextWrapper(this, this.props.initialUser),
  };

  render() {
    return (
      <UserContext.Provider value={this.state.context}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const useUser = () => useContext(UserContext);
