"use client";

import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { UserContext } from "./UserContext";
import { get } from "./utils/fetch";

const { fetch__coaches, fetch__schools, fetch__students } = {
  fetch__coaches: () => get("mentors/list"),
  fetch__schools: () => get("schools/list"),
  fetch__students: () => get("students/list"),
};

interface AnalyticsState {
  coaches: any[];
  schools: any[];
  students: any[];
  is_loading: boolean;
}

interface AnalyticsContextType extends AnalyticsState {
  set__coaches: (coaches: any[]) => void;
  set__schools: (schools: any[]) => void;
  set__students: (students: any[]) => void;
  add__school: (school: any) => void;
  add__student: (student: any) => void;
  add__coach: (coach: any) => void;
  edit__coach: (id: number, name: string) => void;
  edit__school: (id: number, name: string) => void;
  edit__student: (id: number, name: string) => void;
  remove__student: (id: number) => void;
  remove__coach: (id: number) => void;
  remove__school: (id: number) => void;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(
  undefined
);

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error("useAnalytics must be used within an AnalyticsProvider");
  }
  return context;
};

interface AnalyticsProviderProps {
  children: ReactNode;
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const { logged_in, is_loading: is_loading_user } = useContext(UserContext);

  const [is_loading, set__is_loading] = useState(true);

  const [coaches, set__coaches] = useState<any[]>([]);
  const [schools, set__schools] = useState<any[]>([]);
  const [students, set__students] = useState<any[]>([]);

  const add__school = (school: any) => set__schools([...schools, school]);
  const add__student = (student: any) => set__students([...students, student]);
  const add__coach = (coach: any) => set__coaches([...coaches, coach]);

  const edit__coach = (id: number, name: string) => {
    set__coaches(coaches.map((c) => (c.mentor.id === id ? { ...c, name } : c)));
  };

  const edit__school = (id: number, name: string) => {
    set__schools(schools.map((s) => (s.id === id ? { ...s, name } : s)));
  };

  const edit__student = (id: number, full_name: string) => {
    set__students(students.map((s) => (s.id === id ? { ...s, full_name } : s)));
  };

  const remove__student = (id: number) => {
    set__students(students.filter((s) => s.id !== id));
  };

  const remove__coach = (id: number) => {
    set__coaches(coaches.filter((c) => c.mentor.id !== id));
  };

  const remove__school = (id: number) => {
    set__schools(schools.filter((s) => s.id !== id));
  };

  useEffect(() => {
    const fetch__data = async () => {
      try {
        set__is_loading(true);

        const [_coaches, _schools, _students] = await Promise.all([
          fetch__coaches(),
          fetch__schools(),
          fetch__students(),
        ]);

        set__coaches(_coaches || []);
        set__schools(_schools || []);
        set__students(_students || []);

        set__is_loading(false);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    };

    if (logged_in && !is_loading_user) fetch__data();
  }, [logged_in, is_loading_user]);

  const value = {
    coaches,
    schools,
    students,
    set__coaches,
    set__schools,
    set__students,
    add__coach,
    add__school,
    add__student,
    edit__coach,
    edit__school,
    edit__student,
    remove__student,
    remove__coach,
    remove__school,
    is_loading,
  };

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
