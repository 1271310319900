import(/* webpackMode: "eager", webpackExports: ["AnalyticsProvider"] */ "/vercel/path0/admin2/AnalyticsContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/admin2/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/admin2/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/admin2/node_modules/@fontsource/roboto/300.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/admin2/node_modules/@fontsource/roboto/500.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/admin2/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/admin2/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/admin2/node_modules/@fontsource/roboto/400.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/admin2/node_modules/@fontsource/roboto/700.css");
;
import(/* webpackMode: "eager", webpackExports: ["UserContextProvider"] */ "/vercel/path0/admin2/UserContext.tsx");
